import React from 'react'
import '../../styles/components/ui/backTop.css'

export const BackTopComponent = ({ returnLink }) => {
  return (
    <>
        <div className="back-top">
            <a href={returnLink}>
                <img src="../assets/punta-de-flecha-hacia-arriba.png" alt="puntaDeFlecha-arriba" />
            </a>
        </div>
    </>
  )
}
