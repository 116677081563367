import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { BackTopComponent } from '../components/ui/BackTopComponent'
import '../styles/page/vehicles.css'
import { useLocation } from 'react-router-dom'

export const VehiclesPage = () => {
  const location = useLocation();
  const returnUrl = `..${location.pathname}#`;
  return (
    <>
        <HeaderComponent />
        <div className='options'>
          <div className='option'><span><p><a href="../vehicles#corolla-vehicle" className='option-link'> Corolla & Civic </a></p></span></div>
          <div className='option'><span><p><a href="../vehicles#starex-vehicle" className='option-link'> H1 & Starex </a></p></span></div>
          <div className='option'><span><p><a href="../vehicles#hiAce-vehicle" className='option-link'> Toyota Hi Ace </a></p></span></div>
          <div className='option'><span><p><a href="../vehicles#county-vehicle" className='option-link'> Hyundai County </a></p></span></div>
          <div className='option'><span><p><a href="../vehicles#coaster-vehicle" className='option-link'> Toyota Coaster </a></p></span></div>
          <div className='option'><span><p><a href="../vehicles#pullman-vehicle" className='option-link'> Pullman </a></p></span></div>
          <div className='option'><span><p><a href="../vehicles#pullmanDeluxe-vehicle" className='option-link'> Pullman Deluxe </a></p></span></div>
        </div>
        <div className="gallery">
          <div className='gallery-img'><img src="../assets/vehicles/corolla.png" alt="corolla-travelCenterCorporativo" id="corolla-vehicle" /><BackTopComponent returnLink={returnUrl} /></div>
          <div className='gallery-img'><img src="../assets/vehicles/starex.png" alt="starex-travelCenterCorporativo" id="starex-vehicle" /><BackTopComponent returnLink={returnUrl} /></div>
          <div className='gallery-img'><img src="../assets/vehicles/hiAce.png" alt="hiAce-travelCenterCorporativo" id="hiAce-vehicle" /><BackTopComponent returnLink={returnUrl} /></div>
          <div className='gallery-img'><img src="../assets/vehicles/county.png" alt="county-travelCenterCorporativo" id="county-vehicle" /><BackTopComponent returnLink={returnUrl} /></div>
          <div className='gallery-img'><img src="../assets/vehicles/coaster.png" alt="coaster-travelCenterCorporativo" id="coaster-vehicle" /><BackTopComponent returnLink={returnUrl} /></div>
          <div className='gallery-img'><img src="../assets/vehicles/pullman.png" alt="pullman-travelCenterCorporativo" id="pullman-vehicle" /><BackTopComponent returnLink={returnUrl} /></div>
          <div className='gallery-img'><img src="../assets/vehicles/pullmanDeluxe.png" alt="pullmanDeluxe-travelCenterCorporativo" id="pullmanDeluxe-vehicle" /><BackTopComponent returnLink={returnUrl} /></div>
        </div>
    </>
  )
}
