import React from 'react'
import '../styles/page/home.css';
import { HeaderComponent } from '../components/HeaderComponent';

export const HomePage = () => {
  return (
    <>
        <HeaderComponent />
        <img src="../assets/portada.jpg" alt="bus-traveclcentercorporativo" />
    </>
  )
}
