import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import '../styles/page/quote.css'

export const QuotePage = () => {

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  return (
    <>
        <HeaderComponent />
        <form onSubmit={handleSubmit}>
          <label htmlFor="Nombre">Nombre</label>
          <input type="text" className='form-control' name='Nombre' id='Nombre' autoComplete='off' />
          <br />
          <label htmlFor="Correo electrónico">Correo Electrónico</label>
          <input type="email" className='form-control' name='Email' id='Email' autoComplete='off' />
          <br />
          <label htmlFor="Date">Fecha</label>
          <input type="date" className='form-control' name='Fecha' id='Fecha' />
          <br />
          <label htmlFor="Notas">Notas</label><br />
          <textarea rows={5} cols={30} className='form-control' placeholder='Escribe tus notas aqui...' ></textarea>
          <br />
          <button className="btn btn-outline-primary form-control">Solicitar</button>
        </form>
    </>
  )
}
