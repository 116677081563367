import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { QuotePage } from '../pages/QuotePage';
import { ServicesPage } from '../pages/ServicesPage';
import { VehiclesPage } from '../pages/VehiclesPage';
import { HomePage } from '../pages/HomePage';

const routes = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/services',
    element: <ServicesPage />
  },
  {
    path: '/vehicles',
    element: <VehiclesPage />
  },
  {
    path: '/quote-request',
    element: <QuotePage />,
    
  },
];

export const AppRouter = createBrowserRouter(routes, {
  basename: '/',
});


