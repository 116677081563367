import React from 'react'
import '../styles/components/header.css'
import { useLocation, useNavigate } from 'react-router-dom';

export const HeaderComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = e => {
	  e.preventDefault();
    // console.log(e.target.text);
    const section = e.target.text;
    if (section === 'Home'){
      navigate('../');
    } else if (section === 'Servicios'){
      navigate('../services');
    } else if (section === 'Vehículos'){
      navigate('../vehicles');
    } else if (section === 'Cotización'){
      navigate('../quote-request');
    }

    if (location.pathname === '/'){
      // window.scrollTo({top: 0});
      const homeActive = document.getElementById('home');
      homeActive.className = 'active'
    } else if(location.pathname === '/services'){
      const servicesActive = document.getElementById('services');
      servicesActive.className = 'active'
    } else if(location.pathname === '/vehicles'){
      const vehiclesActive = document.getElementById('vehicles');
      vehiclesActive.className = 'active'
    } else if(location.pathname === '/quote-request'){
      const quoteActive = document.getElementById('quote');
      quoteActive.className = 'active'
    }
  };
  return (
    <nav>
        <ul>
            <a className="" id="home" onClick={handleClick} href="../" >Home</a>
            <a className="" id="services" onClick={handleClick} href='../services' >Servicios</a>
            <a className="" id="vehicles" onClick={handleClick} href='../vehicles' >Vehículos</a>
            <a className="" id="quote" onClick={handleClick} href='../quote-request' >Cotización</a>
        </ul>
    </nav>
  )
}
