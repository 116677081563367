import React from 'react'
// import { HeaderComponent } from './components/HeaderComponent'
import { RouterProvider } from 'react-router-dom'
import { AppRouter } from './router/AppRouter'

export const CorporativoApp = () => {
  return (
    <>
        {/* <HeaderComponent /> */}
        <RouterProvider router={AppRouter} />
    </>
  )
}
