import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'

export const ServicesPage = () => {
  return (
    <>
        <HeaderComponent />
        <img src="../assets/portada.jpg" alt="servicios-travelCenterCorporativo" />
    </>
  )
}
